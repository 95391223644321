import { IChangeOrderRes, IChangeOrderType } from "./types";


export const ActionTypes = {
  SET_CHANGE_ORDER_LIST: "SET_CHANGE_ORDER_LIST",
  
  SET_ADD_CHANGE_ORDER: "SET_ADD_CHANGE_ORDER",
  SET_DELETE_CHANGE_ORDER: "SET_DELETE_PROJECT_DETAILS",
 
};

export const setChangeOrderList = (payload: IChangeOrderType) => ({
  type: ActionTypes.SET_CHANGE_ORDER_LIST,
  payload,
});


export const setDeleteChangeOrder = (payload: IChangeOrderType) => ({
  type: ActionTypes.SET_DELETE_CHANGE_ORDER,
  payload,
});

export const setAddChangeOrder = (payload: IChangeOrderRes) => ({
  type: ActionTypes.SET_ADD_CHANGE_ORDER,
  payload,
});

import { useSelector } from "react-redux";
import { RootState } from "../store";
import { createSelector } from "reselect";

// Create memoized selector
const selectPosterReducers = createSelector(
  (state: RootState) => state?.combinedReducer,
  (combinedReducer) => ({
    ...combinedReducer?.posterReducers,
    ...combinedReducer?.countries,
    ...combinedReducer?.distributionReducers,
    ...combinedReducer?.emailSetupReducers,
    ...combinedReducer?.credentialReducers,
    ...combinedReducer?.customersReducers,
    ...combinedReducer?.inventory_list,
    ...combinedReducer?.projectsReducers,
    ...combinedReducer?.fabricationReducers,
    ...combinedReducer?.shopReducers,
    ...combinedReducer?.stockTransferReducers,
    ...combinedReducer?.proposalReducers,
    ...combinedReducer?.logisticReducer,
    ...combinedReducer?.chatReducer,
    roles: combinedReducer?.roles,
    external_user: combinedReducer?.external_user,
    internal_user: combinedReducer?.internal_user,
    locality_list: combinedReducer?.locality_list,
    socketResponse: combinedReducer?.posterReducers.socketResponse,
    country_list: combinedReducer?.countries?.country_list,
    project_type: combinedReducer?.project_type,
    scope_of_project: combinedReducer?.scope_of_project,
    products:combinedReducer?.products,
    supplier_type:combinedReducer?.supplier_type,
    supplier:combinedReducer?.supplier,
    size_spec:combinedReducer?.size_spec,
    scope_of_work:combinedReducer?.scope_of_work,
    grade:combinedReducer?.grade,
    coating:combinedReducer?.coating,
    tax:combinedReducer?.tax,
    productLength:combinedReducer?.productLength,
    productUnitService:combinedReducer?.productUnitService,
    status_of_drawing:combinedReducer?.status_of_drawing,
    user_data:combinedReducer?.user_data,
    user_id: combinedReducer?.user_data?.user?.id,
    user: combinedReducer?.user_data?.user,
    request_quotation:combinedReducer?.distributionReducers?.request_quotation,
    get_request_quotation:combinedReducer?.distributionReducers?.get_request_quotation,
    supplierList:combinedReducer?.distributionReducers?.supplierList,
    sale_order_list:combinedReducer?.distributionReducers?.sale_order,
    delivered_list:combinedReducer?.distributionReducers?.delivered_list,
    stock_supply_delivered_list:combinedReducer?.stockSupplyReducers,
    emailTemplate:combinedReducer?.emailTemplateReducers,
    email_details:combinedReducer?.distributionReducers?.email_details,
    deliveryBy: combinedReducer?.deliveryByServiceReducers,
    logisticLocation: combinedReducer?.logisticLocationServiceReducers,
    checklist:combinedReducer?.checkList,
    packageList: combinedReducer?.projectsReducers?.packageList,
    proposal_list:combinedReducer?.proposalReducers,
    logisticsList:combinedReducer?.logisticReducer,
    dashboard_data:combinedReducer?.dashboardReducers,
    listLogList: combinedReducer?.projectsReducers?.listLogList,
    revisionHistoryList: combinedReducer?.projectsReducers?.revisionHistoryList,
    drawingVersionList: combinedReducer?.projectsReducers?.drawingVersionList,
    webmailReducer:combinedReducer?.webmailReducer,
    webMailList:combinedReducer?.webmailReducer?.webmailList,
    changeOrderList:combinedReducer?.changeOrderReducer.changeOrderList,
    chatMessageList:combinedReducer?.chatReducer?.chatMessageList,
    userList:combinedReducer?.chatReducer?.usersList,
    chatSummaryList:combinedReducer?.chatReducer?.chatSummaryList,
    notificationList:combinedReducer?.chatReducer?.notificationList,
    // newMessageList:combinedReducer?.chatReducer?.newMessageList,
    groupList:combinedReducer?.chatReducer?.groupList,
    searchList:combinedReducer?.searchReducer?.searchList,
    chatUsersList:combinedReducer?.chatReducer?.chatUsersList,
    sale_order_module:combinedReducer?.saleOrderList,
    projectGroupList:combinedReducer?.chatReducer?.projectGroupList,
    emojiList:combinedReducer?.chatReducer?.emojilist,
    purchase_order_module:combinedReducer?.purchaseOrderList,
    group_details:combinedReducer?.chatReducer?.groupDetails,
  })
);

export const usePosterReducers = () => {
  return useSelector(selectPosterReducers);
};
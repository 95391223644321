import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import posterReducer from "./reducers/posterReducer";
import uiReducer from "./reducers/uiReducer";
import {
  externalUserReducer,
} from "./modules/externalUser";
import { rolesReducers } from "./modules/roles";
import { internalUserReducers } from "./modules/internal_user";
import { localityReducers } from "./modules/allMasters/locality";
import { countriesReducers } from "./modules/countries";
import {
  projectTypeReducers,
} from "./modules/allMasters/project_type";
import { productReducers } from "./modules/allMasters/products";
import {
  supplierTypeReducers,
} from "./modules/allMasters/supplier_type";
import { supplierReducers } from "./modules/allMasters/supplier";
import {
  sizeSpecsReducers,
} from "./modules/allMasters/sizeSpecs";
import {
  scopeOfWorkReducers,
} from "./modules/allMasters/scope_of_work";
import { gradeReducers } from "./modules/allMasters/grade";
import { coatingReducers } from "./modules/allMasters/coating";
import { taxReducers } from "./modules/allMasters/tax";

import { scopeOfProjectReducers } from "./modules/allMasters/scope_of_project";
import { userDataReducers } from "./modules/user_data";
import statusOfDrawingReducers from "./modules/allMasters/status_of_drawing/reducer";
import { distributionReducers } from "./modules/distribution";
import emailSetupReducers from "./modules/allMasters/sender_setup/reducer";
import emailTemplateReducers from "./modules/emailTemplate/reducer";
import credentialReducers from "./modules/allMasters/api_credentials/reducer";
import customersReducers from "./modules/customers/reducer";
import deliveryByServiceReducers from "./modules/allMasters/deliveryByService/reducer";
import inventoryReducers from "./modules/inventory/reducer";
import fabricationReducers from "./modules/fabrication/reducer";
import productLengthReducers from "./modules/allMasters/productLength/reducer";
import { ChecklistReducers } from "./modules/checklist";
import projectsReducers from "./modules/projectManagement/reducer";
import stockSupplyReducers from "./modules/order_supply/reducer";


// import { orderStockReducers } from "./modules/order_supply";
import productUnitServiceReducers from "./modules/allMasters/unit/reducer";
import { proposalReducers } from "./modules/proposal";
import dashboardReducers from "./modules/dashboard/reducer";
import { logisticReducer } from "./modules/logistic/index";
import logisticLocationServiceReducers from "./modules/allMasters/logisticLocationService/reducer";
import webmailReducer from "./modules/webMail/reducer";
import { changeOrderReducer } from "./modules/changeOrder";
import { chatReducer } from "./modules/chat";
import shopReducers from "./modules/shop/reducer";
import searchReducer from "./modules/search/reducer";
import stockTransferReducers from "./modules/stockTransfer/reducer";
import saleOrderReducer from "./modules/saleOrderModule/reducer";
import PurchaseOrderReducer from "./modules/purchaseOrderModule/reducer";



const combinedReducer = combineReducers({
  roles: rolesReducers,
  external_user: externalUserReducer,
  internal_user: internalUserReducers,
  locality_list: localityReducers,
  countries: countriesReducers,
  project_type: projectTypeReducers,
  scope_of_project: scopeOfProjectReducers,
  products: productReducers,
  supplier_type: supplierTypeReducers,
  supplier: supplierReducers,
  size_spec: sizeSpecsReducers,
  scope_of_work: scopeOfWorkReducers,
  grade: gradeReducers,
  coating: coatingReducers,
  productLength: productLengthReducers,
  productUnitService: productUnitServiceReducers,
  tax: taxReducers,
  status_of_drawing: statusOfDrawingReducers,
  user_data: userDataReducers,
  distributionReducers:distributionReducers,
  stockSupplyReducers:stockSupplyReducers,
  emailSetupReducers:emailSetupReducers,
  emailTemplateReducers:emailTemplateReducers,
  credentialReducers:credentialReducers,
  customersReducers:customersReducers,
  deliveryByServiceReducers: deliveryByServiceReducers,
  inventory_list:inventoryReducers,
  fabricationReducers:fabricationReducers,
  shopReducers:shopReducers,
  stockTransferReducers:stockTransferReducers,
  checkList:ChecklistReducers,
  projectsReducers:projectsReducers,
  proposalReducers:proposalReducers,
  logisticReducer:logisticReducer,
  logisticLocationServiceReducers:logisticLocationServiceReducers,
  dashboardReducers:dashboardReducers,
  webmailReducer:webmailReducer,
  changeOrderReducer:changeOrderReducer,
  chatReducer:chatReducer,
  searchReducer:searchReducer,
  saleOrderList:saleOrderReducer,
  purchaseOrderList:PurchaseOrderReducer,
  posterReducers: posterReducer,
  uiReducer: uiReducer,
});

const rootReducer = combineReducers({
  combinedReducer: persistReducer(
    { key: "chathub-store", storage, blacklist: ["uiReducer"] },
    combinedReducer
  ),
});

export function makeStore() {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        // serializableCheck: {
        //   ignoredActions: ["persist/PERSIST"], // Ignore serializability check for specific actions
        // },
        serializableCheck: false,
        immutableCheck: false,
      }),
  });

  const persistor = persistStore(store);
  return { store, persistor };
}

const { store, persistor } = makeStore();
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { store, persistor };

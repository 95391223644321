/* eslint-disable array-callback-return */

import { ActionTypes } from './action';
import { IChangeOrderRes } from "./types";


const initialState: IChangeOrderRes = {
  changeOrderList: {
    optionsList: [],
    items: [],
    totalCount: 0,
  },
  changeOrderDetails: null,
  
};

const changeOrderReducers = (state: IChangeOrderRes = initialState, action): IChangeOrderRes => {
  switch (action?.type) {
    case ActionTypes.SET_CHANGE_ORDER_LIST: {
      const optionsList = action?.payload?.items?.map((item, index) => ({
        ...item,
        label: item?.name,
        value: item?.id
      }));
      return {
        ...state,
        changeOrderList: {
          ...action?.payload,
          optionsList: optionsList,
        },
      };
    }
    case ActionTypes.SET_ADD_CHANGE_ORDER: {
      const items = [];
      items.push(action?.payload);
      state?.changeOrderList.items?.map((item, index) => {
        items.push(item);
      })
      const totalCount = state?.changeOrderList.totalCount + 1;
      return {
        ...state,
        changeOrderList: {
          items: items,
          totalCount: totalCount,
        }
      };
    }
    case ActionTypes.SET_DELETE_CHANGE_ORDER: {
      const updatedItems = state.changeOrderList.items.filter(item => item.id !== action.payload.id);
      const updatedTotalCount = state.changeOrderList.totalCount - 1;

      return {
        ...state,
        changeOrderList: {
          ...state.changeOrderList,
          items: updatedItems,
          totalCount: updatedTotalCount,
        },
      };
    }
   
    default:
      return state;
  }
};

export default changeOrderReducers;

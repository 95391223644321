import { useSelector } from "react-redux";
import { RootState } from "../store";
import { createSelector } from "reselect";

const selectPosterReducers = createSelector(
  (state: RootState) => state.combinedReducer,
  (combinedReducer) => ({
    ...combinedReducer?.uiReducer,
    formContent: combinedReducer?.uiReducer?.formContent,
    ModalPopup: combinedReducer?.uiReducer?.ModalPopup,
    requestLoader: combinedReducer?.uiReducer?.requestLoader,
  })
)
export const useUiReducer = () => {
    return useSelector(selectPosterReducers);
};
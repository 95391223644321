// src/app/redux/modules/webMail/action.ts

import { IWebmailRes, IWebmailListTypes, IWebmailTypes } from "./types";

export const ActionTypes = {
  SET_WEBMAIL_LIST: "SET_WEBMAIL_LIST",
  SET_ADD_WEBMAIL: "SET_ADD_WEBMAIL",
  SET_DELETE_WEBMAIL: "SET_DELETE_WEBMAIL",
  SET_WEBMAIL_DETAILS: "SET_WEBMAIL_DETAILS",
  CLEAR_WEBMAIL_DETAILS: "CLEAR_WEBMAIL_DETAILS",
  ADD_MAIL_TO_LIST: "ADD_MAIL_TO_LIST",
};

export const setWebmailList = (payload: IWebmailListTypes) => ({
  type: ActionTypes.SET_WEBMAIL_LIST,
  payload,
});

export const setAddWebmail = (payload: IWebmailListTypes) => ({
  type: ActionTypes.SET_ADD_WEBMAIL,
  payload,
});

export const setDeleteWebmail = (payload: { id: string }) => ({
  type: ActionTypes.SET_DELETE_WEBMAIL,
  payload,
});

export const setWebmailDetails = (payload: IWebmailRes) => ({
  type: ActionTypes.SET_WEBMAIL_DETAILS,
  payload,
});

export const clearWebmailDetails = () => ({
  type: ActionTypes.CLEAR_WEBMAIL_DETAILS,
});

export const addMailToList = (mail: IWebmailTypes) => ({
  type: ActionTypes.ADD_MAIL_TO_LIST,
  payload: mail,
});
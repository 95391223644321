/* eslint-disable @typescript-eslint/no-unused-vars */
// src/app/redux/modules/webMail/reducer.ts

import { ActionTypes } from './action';
import { IWebmailRes, IWebmailTypes } from './types';

const initialState: IWebmailRes = {
  webmailList: {
    items: [],
    totalCount: 0,
    optionList: [],
  },
  webmailDetails: null,
};

const webmailReducer = (state: IWebmailRes = initialState, action): IWebmailRes => {
  switch (action?.type) {
    case ActionTypes.SET_WEBMAIL_LIST: {
      return {
        ...state,
        webmailList: action.payload,
      };
    }

    case ActionTypes.SET_ADD_WEBMAIL: {
      const updatedItems = [...state.webmailList.items, action.payload];
      return {
        ...state,
        webmailList: {
          ...state.webmailList,
          items: updatedItems,
          totalCount: updatedItems.length,
        },
      };
    }

    case ActionTypes.SET_DELETE_WEBMAIL: {
      const updatedItems = state.webmailList.items.filter(
        (item) => item.id !== action.payload.id
      );
      return {
        ...state,
        webmailList: {
          ...state.webmailList,
          items: updatedItems,
          totalCount: updatedItems.length,
        },
      };
    }

    case ActionTypes.ADD_MAIL_TO_LIST: {
      // Check if the mail is already in the list
      const existingMailIndex = state.webmailList.items.findIndex(
        (mail) => mail.id === action.payload.id
      );

      let updatedItems;
      if (existingMailIndex === -1) {
        // Mail not found in list, add it
        updatedItems = [...state.webmailList.items, action.payload];
      } else {
        // Mail already in list, update if necessary or leave as is
        updatedItems = state.webmailList.items.map((mail) =>
          mail.id === action.payload.id ? action.payload : mail
        );
      }

      return {
        ...state,
        webmailList: {
          ...state.webmailList,
          items: updatedItems,
          totalCount: updatedItems.length,
        },
      };
    }

    case ActionTypes.SET_WEBMAIL_DETAILS: {
      return {
        ...state,
        webmailDetails: action.payload,
      };
    }

    case ActionTypes.CLEAR_WEBMAIL_DETAILS: {
      return {
        ...state,
        webmailDetails: null,
      };
    }

    default:
      return state;
  }
};

export default webmailReducer;

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
import { toast } from "react-toastify";
import {
  setFormPopup,
  setModalLoader,
  setRequestLoader,
  setShowModalPopup,
  setStoreSocketResponse,
  WarnFormSetFunctions,
} from "../../redux/actions/action";
// import { location_list } from "../../utils/constants/payload";
import { App_url } from "../../utils/constants/static";
import {
  deleteRole,
  setRoleList,
  updateRole,
  addRole,
} from "../../redux/modules/roles/action";
import {
  addExternalUser,
  deleteExternalUser,
  setExternalUserList,
  updateExternalUser,
} from "../../redux/modules/externalUser/action";
import {
  addInternalUser,
  deleteInternalUser,
  setInternalUserList,
  updateInternalUser,
} from "../../redux/modules/internal_user/action";
import {
  addLocality,
  deleteLocality,
  setLocalityList,
  updateLocality,
} from "../../redux/modules/allMasters/locality/action";
import {
  setStoreCityList,
  setStoreCitySelectStateList,
  setStoreCountryList,
  setStoreStateList,
  setStoreStateSelectCountryList,
} from "../../redux/modules/countries/action";

import {
  addProjectType,
  deleteProjectType,
  setProjectTypeList,
  updateProjectType,
} from "../../redux/modules/allMasters/project_type/action";
import {
  addProductType,
  deleteProductType,
  setProductTypeList,
  updateProductType,
} from "../../redux/modules/allMasters/products/action";
import {
  addSupplierType,
  deleteSupplierType,
  setSupplierTypeList,
  updateSupplierType,
} from "../../redux/modules/allMasters/supplier_type/action";
import {
  addScopeOfProject,
  deleteScopeOfProject,
  setScopeOfProjectList,
  updateScopeOfProject,
} from "../../redux/modules/allMasters/scope_of_project/action";
import { addScopeOfWorkType, deleteScopeOfWorkType, setScopeOfWorkTypeList, updateScopeOfWorkType } from "../../redux/modules/allMasters/scope_of_work/action";
import { addSupplier, deleteSupplier, setSupplierList, updateSupplier } from "../../redux/modules/allMasters/supplier/action";
import { addSizeSpecs, deleteSizeSpecs, setSizeSpecsList, updateSizeSpecs } from "../../redux/modules/allMasters/sizeSpecs/action";
import { addGradeType, deleteGradeType, setGradeTypeList, updateGradeType } from "../../redux/modules/allMasters/grade/action";
import { addCoating, deleteCoating, setCoatingList, updateCoating } from "../../redux/modules/allMasters/coating/action";
import { addTax, deleteTax, setTaxList, updateTax } from "../../redux/modules/allMasters/tax/action";
import { addStatusOfDrawing, deleteStatusOfDrawing, setStatusOfDrawingList, updateStatusOfDrawing } from "../../redux/modules/allMasters/status_of_drawing/action";
import { setStoreEmailSetupList } from "../../redux/modules/allMasters/sender_setup/action";
import { deleteReqQuotation, setReqQuotationList, getReqQuotation, setStoreInquirySupplierList, setStoreSupplierList, setStoreInquiryDetails, setSaleOrderList, addSaleOrder, changeSaleOrderStatus, deleteSaleOrder, setPOList, setStoreInquirySaleDetails, setStoreInquirySupplierSale, deleteScheduledDelivery, setStoreScheduledList, setDeliveredList, setInquiryDetail, setEmailDetail, setIntuitList, setStoreInquiryItemDetails, setIntuitTaxList, setAddPadlet, addReqQuotation, setStoreTaxRate, setStoreInquirySaleData, setIntuitTerms, deleteBOL, setUpdateDeliveredList } from "../../redux/modules/distribution/action";
import { setStoreEmailTemplateList } from "../../redux/modules/emailTemplate/action";
import { getData, postData } from "../rest/fetchData";
import { setStoreCredentialList } from "../../redux/modules/allMasters/api_credentials/action";
import { setStoreCustomersDetails, setStoreCustomersList } from "../../redux/modules/customers/action";
import { addDeliveryByServiceType, deleteDeliveryByServiceType, setDeliveryByServiceTypeList, updateDeliveryByServiceType } from "../../redux/modules/allMasters/deliveryByService/action";
import { setStoreInventoryDetails, setStoreInventoryList, setStoreInventoryStockList, setStorePricingInventoryList, setStoreUpdateInventoryStock } from "../../redux/modules/inventory/action";
import { setStoreFabricationDeliveredList, setStoreFabricationDetails, setStoreFabricationList, setStoreFabricationScheduleList } from "../../redux/modules/fabrication/action";
import { setAddListlogDetails, setAddPackagesDetails, setAddProjectsDetails, setAddRevisionHistoryDetails, setAssignProjectDetails, setDeleteDrawingVersion, setDeleteListLog, setDeletePackageDrawing, setDeleteProjectDetails, setDeleteRevisionHistory, setStoreDrawingVersionList, setStoreFabricationProjectsDetails, setStoreListLogList, setStorePackageDrawingList, setStorePackagesList, setStoreProjectsDetails, setStoreProjectShopSupply, setStoreRevisionHistoryList } from "../../redux/modules/projectManagement/action";
import { addProductLength, deleteProductLength, setProductLengthList, updateProductLength } from "../../redux/modules/allMasters/productLength/action";
import { addChecklistQuestion, deleteChecklistQuestion, setChecklistQuestionList, updateChecklistQuestion } from "../../redux/modules/checklist/actions";
import { setStoreProjectsList } from "../../redux/modules/projectManagement/action";
import { setStockSupplyList } from "../../redux/modules/order_supply/action";
import { addUnitService, deleteUnitService, setUnitServiceList, updateUnitService } from "../../redux/modules/allMasters/unit/action";
import { deleteProposal, setProposalDetails, setProposalList, setProposalStatus } from "../../redux/modules/proposal/action";
import { setUpdateUserLogin } from "../../redux/modules/user_data/action";
import { setLogisticList } from "../../redux/modules/logistic/action";
import { setDashboardData } from "../../redux/modules/dashboard/action";
import { setLogisticLocationServiceTypeList } from "../../redux/modules/allMasters/logisticLocationService/action";
import { openPreview } from "../../utils/common";
import { setAddChangeOrder, setChangeOrderList, setDeleteChangeOrder } from "../../redux/modules/changeOrder/action";
import { setStoreMessage, setAddNotificationList, setStoreGroupAdd, setFilteredGroups, setStoreMessageList, setStoreUpdateUserChat, setStoreChannelDetails, setStoreUpdateSingleChatUsersList, setStoreUpdateChatCount, setStoreUpdateChatUsers, setRemoveUserFromGroup, setAddUserToGroup, setGroupDetails } from "../../redux/modules/chat/actions";
import { setSearchList } from "../../redux/modules/search/action";
import { deleteShopSupplyDelivery, setStoreShopDeliveredList, setStoreShopDetails, setStoreShopList, setStoreShopScheduleList } from "../../redux/modules/shop/action";
import { deleteStockTransferList, setStoreInventoryTransferList, setStoreStockToggleList, setStoreStockTransferDetails, setStoreStockTransferList, setStoreStockTransferParent,setUpdateStockToggleList } from "../../redux/modules/stockTransfer/action";
import { setDeleteOrder, setSaleOrderModuleList, setSaleUpdateStatus } from "../../redux/modules/saleOrderModule/action";
import { setPurchaseDeleteOrder, setPurchaseOrderModuleList, setPurchaseUpdateStatus } from "../../redux/modules/purchaseOrderModule/action";


export const handleSocketResponse = async (
  type: string,
  data: any,
  dispatch: any,
  send: any,
  navigate: any,
  user_data?: any,
  access_token?: any,
) => (async (dispatch, getState) => {

  const response = data;
  const { requestLoader, modalLoader, PreviewPopup, clearForm } = getState()?.combinedReducer?.uiReducer;
  const { ChannelDetails, chatUsersList } = getState()?.combinedReducer?.chatReducer;
  const { user } = getState()?.combinedReducer?.user_data;
  const internal_user = getState()?.combinedReducer?.internal_user;
  const external_user = getState()?.combinedReducer?.external_user;
  const { stockTransferList } = getState()?.combinedReducer?.stockTransferReducers;
  const payloadRequest = {
    ...data,
    action: data?.request?.action,
    type: data?.request?.type,
    payload: data?.request?.payload,
  };
  dispatch(setStoreSocketResponse(payloadRequest));

  setTimeout(() => {
    dispatch(setStoreSocketResponse());
  }, 500);
  if (data?.request?.demo?.handle_modal && data?.status === true) {
    dispatch(setFormPopup({ status: "hide" }));
  }
  if (requestLoader == `${data?.request?.type}:${data?.request?.action}`) {
    dispatch(setRequestLoader(""));
  }
  if (modalLoader == `${data?.request?.type}:${data?.request?.action}`) {
    setTimeout(() => {
      dispatch(setModalLoader(""));
      dispatch(setShowModalPopup());
    }, 70)
    if (PreviewPopup.callBackModal) {
      PreviewPopup.callBackModal(data)
    }
  }
  switch (data?.request?.type) {
    case "locationFacilityService":
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(addLocality(data?.data));
          dispatch(
            setFormPopup({ url: data?.request?.type, status: "hide" })
          );
        }
      }
      if (data?.request?.action === "update" || data?.request?.action === "updateStatus") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(updateLocality(data?.data));

          dispatch(
            setFormPopup({ url: data?.request?.type, status: "hide" })
          );
        }
      }
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setLocalityList(data?.data));
        }
        if (data?.status === false) {
          dispatch(
            setLocalityList({ items: [], totalCount: 0, optionList: [] })
          );
        }
      }
      if (data?.request?.action === "delete") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(deleteLocality(data?.request?.payload));
          dispatch(WarnFormSetFunctions({ status: "hide" }));
        }
      }

      break;

    case "userRoleService":
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(addRole(data?.data));
          navigate(App_url.link.ROLES_AND_PERMISSION);
        }
      }
      if (data?.request?.action === "update") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(updateRole(data?.data));
          navigate(App_url.link.ROLES_AND_PERMISSION);
        }
      }
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setRoleList(data?.data));
        }
        if (data?.status === false) {
          dispatch(setRoleList({ items: [], totalCount: 0, optionList: [] }));
        }
      }
      if (data?.request?.action === "delete") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(deleteRole(data?.request?.payload));
          dispatch(WarnFormSetFunctions({ status: "hide" }));
        }
        if (data?.status === false) {
          dispatch(WarnFormSetFunctions({ url: "Delete Role", status: "hide" }));
        }
      }
      if (data?.request?.action === "updateStatus") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(updateRole(data?.data));
        }
      }
      break;

    case "userService":
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          if (data?.request?.payload?.user_type === "client") {
            toast.success(data?.msg);
            dispatch(addExternalUser(data?.data));
            navigate(App_url.link.EXTERNAL_USER_URL);
          } else {
            toast.success(data?.msg);
            dispatch(addInternalUser(data?.data));
            navigate(App_url.link.INTERNAL_USER_URL);
          }
        }
      }
      if (data?.request?.action === "update") {
        if (data?.status === true) {
          if (data?.request?.payload?.user_type === "client") {
            toast.success(data?.msg);
            dispatch(updateExternalUser(data?.data));
            navigate(App_url.link.EXTERNAL_USER_URL);
          } else {
            toast.success(data?.msg);
            dispatch(updateInternalUser(data?.data));
            navigate(App_url.link.INTERNAL_USER_URL);
          }
        }
      }
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          if(data?.request?.demo?.request_for == "chat" && chatUsersList?.single){
            dispatch(setStoreUpdateSingleChatUsersList({users_list: data?.data?.items, current_user_id: user?.id}));
          }
          if (data?.request?.payload?.user_type === "client") {
            dispatch(setExternalUserList(data?.data));
          } else {
            dispatch(setInternalUserList(data?.data));
          }
        } else {
          if (data?.request?.payload?.user_type === "client") {
            dispatch(
              setExternalUserList({
                items: [],
                totalCount: 0,
                optionList: [],
              })
            );
          } else {
            dispatch(
              setInternalUserList({
                items: [],
                totalCount: 0,
                optionList: [],
              })
            );
          }
        }
      }
      if (data?.request?.action === "delete") {
        if (data?.status === true) {
          if (data?.request?.demo?.user_type) {
            toast.success(data?.msg);
            dispatch(deleteExternalUser(data?.request?.payload));
            dispatch(WarnFormSetFunctions({ status: "hide" }));
          } else {
            toast.success(data?.msg);
            dispatch(deleteInternalUser(data?.request?.payload));
            dispatch(WarnFormSetFunctions({ status: "hide" }));
          }
        }
        if (data?.status === false) {
          dispatch(WarnFormSetFunctions({ url: "Delete User", status: "hide" }));
        }
      }
      if (data?.request?.action === "changePassword") {
        if (data?.status === true) {
          toast.success(data?.msg);
          // const permission = response?.data?.user
          dispatch(setUpdateUserLogin(data?.data));
        }
      }
      break;
    case "countryService": {
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setStoreCountryList(data?.data));
        }
        if (data?.status === false) {
          dispatch(
            setStoreCountryList({
              serializedItems: [],
              totalCount: 0,
              optionList: [],
            })
          );
        }
      }
      break;
    }
    case "stateService": {
      if (data?.request?.action === "list") {
        // if (data?.status === true) {
        //   dispatch(setStoreStateList(data?.data));
        // }
        // if (data?.status === false) {
        //   dispatch(
        //     setStoreStateList({
        //       serializedItems: [],
        //       totalCount: 0,
        //       optionList: [],
        //     })
        //   );
        // }
        if (data?.status === true) {
          if (
            data?.request?.demo?.search_city &&
            data?.data?.serializedItems?.length === 0
          ) {
            dispatch(
              setStoreStateSelectCountryList(data?.request?.payload?.country_id)
            );
          } else {
            dispatch(setStoreStateList(data?.data));
          }
        }
        if (data?.request?.demo?.search_city && data?.status === false) {
          dispatch(
            setStoreStateSelectCountryList(data?.request?.payload?.country_id)
          );
        } else if (data?.status === false) {
          dispatch(
            setStoreStateList({
              serializedItems: [],
              totalCount: 0,
              optionList: [],
            })
          );
        }
      }
      break;
    }
    case "cityService": {
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          if (
            data?.request?.demo?.search_city &&
            data?.data?.serializedItems?.length === 0
          ) {
            dispatch(
              setStoreCitySelectStateList(data?.request?.payload?.state_id)
            );
          } else {
            dispatch(setStoreCityList(data?.data));
          }
        }
        if (data?.request?.demo?.search_city && data?.status === false) {
          dispatch(
            setStoreCitySelectStateList(data?.request?.payload?.state_id)
          );
        } else {
          if (data?.status === false) {
            dispatch(
              setStoreCityList({
                serializedItems: [],
                totalCount: 0,
                optionList: [],
              })
            );
          }
        }
      }
      break;
    }

    case "projectTypeService":
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(addProjectType(data?.data));
          dispatch(
            setFormPopup({ url: data?.request?.type, status: "hide" })
          );
        }
      }
      if (data?.request?.action === "update") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(updateProjectType(data?.data));

          dispatch(
            setFormPopup({ url: data?.request?.type, status: "hide" })
          );
        }
      }
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setProjectTypeList(data?.data));
        }
        if (data?.status === false) {
          dispatch(setProjectTypeList({ items: [], totalCount: 0 }));
        }
      }
      if (data?.request?.action === "delete") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(deleteProjectType(data?.request?.payload));
          dispatch(WarnFormSetFunctions({ status: "hide" }));
        }
      }
      if (data?.request?.action === "updateStatus") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(updateProjectType(data?.data));
        }
      }
      break;
    case "productService":
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(addProductType(data?.data));
          dispatch(
            setFormPopup({ url: data?.request?.type, status: "hide" })
          );
        }
      }
      if (data?.request?.action === "update") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(updateProductType(data?.data));

          dispatch(
            setFormPopup({ url: data?.request?.type, status: "hide" })
          );
        }
      }
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setProductTypeList(data?.data));
        }
        if (data?.status === false) {
          dispatch(setProductTypeList({ items: [], totalCount: 0, optionList: [] }));
        }
      }
      if (data?.request?.action === "delete") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(deleteProductType(data?.request?.payload));
          dispatch(WarnFormSetFunctions({ status: "hide" }));
        }
      }
      if (data?.request?.action === "updateStatus") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(WarnFormSetFunctions({ status: "hide" }));
          dispatch(updateProductType(data?.data));
        }
      }
      break;
    case "supplierTypeService":
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(addSupplierType(data?.data));
          dispatch(
            setFormPopup({ url: data?.request?.type, status: "hide" })
          );
        }
      }
      if (data?.request?.action === "update") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(updateSupplierType(data?.data));

          dispatch(
            setFormPopup({ url: data?.request?.type, status: "hide" })
          );
        }
      }
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setSupplierTypeList(data?.data));
        }
        if (data?.status === false) {
          dispatch(setSupplierTypeList({ optionList: [], items: [], totalCount: 0 }));
        }
      }
      if (data?.request?.action === "delete") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(deleteSupplierType(data?.request?.payload));
          dispatch(WarnFormSetFunctions({ status: "hide" }));
        }
      }
      if (data?.request?.action === "updateStatus") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(updateSupplierType(data?.data));
        }
      }
      break;
    case "projectScopeService":
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(addScopeOfProject(data?.data));
          dispatch(
            setFormPopup({ url: data?.request?.type, status: "hide" })
          );
        }
      }
      if (data?.request?.action === "update") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(updateScopeOfProject(data?.data));

          dispatch(
            setFormPopup({ url: data?.request?.type, status: "hide" })
          );
        }
      }

      if (data?.request?.action === "projectScopeService") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(WarnFormSetFunctions({ status: "hide" }));
          dispatch(updateScopeOfProject(data?.data));
        }
      }
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setScopeOfProjectList(data?.data));
        }
        if (data?.status === false) {
          dispatch(setScopeOfProjectList({ items: [], totalCount: 0 }));
        }
      }
      if (data?.request?.action === "delete") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(deleteScopeOfProject(data?.request?.payload));
          dispatch(WarnFormSetFunctions({ status: "hide" }));
        }
      }
      if (data?.request?.action === "updateStatus") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(updateScopeOfProject(data?.data));
        }
      }
      break;
    case "scopeOfWorkService":
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(addScopeOfWorkType(data?.data));
          dispatch(
            setFormPopup({ url: data?.request?.type, status: "hide" })
          );
        }
      }
      if (data?.request?.action === "update") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(updateScopeOfWorkType(data?.data));

          dispatch(
            setFormPopup({ url: data?.request?.type, status: "hide" })
          );
        }
      }
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setScopeOfWorkTypeList(data?.data));
        }
        if (data?.status === false) {
          dispatch(setScopeOfWorkTypeList({ items: [], totalCount: 0 }));
        }
      }
      if (data?.request?.action === "delete") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(deleteScopeOfWorkType(data?.request?.payload));
          dispatch(WarnFormSetFunctions({ status: "hide" }));
        }
      }
      if (data?.request?.action === "updateStatus") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(updateScopeOfWorkType(data?.data));
        }
      }
      break;

    case "supplierService":
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(addSupplier(data?.data));
          navigate(App_url.link.SUPPLIER_URL)
        }
      }
      if (data?.request?.action === "update") {
        if (data?.status === true) {
          toast.success(data?.msg);
          navigate(App_url.link.SUPPLIER_URL)
          dispatch(updateSupplier(data?.data));
        }
      }
      if (data?.request?.action === "list") {
        if (data?.request?.demo?.request_for) {
          if (data?.status === true) {
            dispatch(setStoreSupplierList(data?.data));
          } else {
            dispatch(setStoreSupplierList({ items: [], totalCount: 0 }));
          }
        } else {
          if (data?.status === true) {
            dispatch(setSupplierList(data?.data));
          }
          if (data?.status === false) {
            dispatch(setSupplierList({ items: [], totalCount: 0 }));
          }
        }
      }
      if (data?.request?.action === "delete") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(deleteSupplier(data?.request?.payload));
          dispatch(WarnFormSetFunctions({ status: "hide" }));
        }
      }
      if (data?.request?.action === "updateStatus") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(updateSupplier(data?.data));
        }
      }
      break;
    case "sizeSpecificationService":
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(addSizeSpecs(data?.data));
          dispatch(
            setFormPopup({ url: data?.request?.type, status: "hide" })
          );
        }
      }
      if (data?.request?.action === "update") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(updateSizeSpecs(data?.data));

          dispatch(
            setFormPopup({ url: data?.request?.type, status: "hide" })
          );
        }
      }
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setSizeSpecsList(data?.data));
        }
        if (data?.status === false) {
          dispatch(setSizeSpecsList({ items: [], totalCount: 0, optionList: [] }));
        }
      }
      if (data?.request?.action === "delete") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(deleteSizeSpecs(data?.request?.payload));
          dispatch(WarnFormSetFunctions({ status: "hide" }));
        }
      }
      if (data?.request?.action === "updateStatus") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(updateSizeSpecs(data?.data));
        }
      }
      break;
    case "gradeService":
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(addGradeType(data?.data));
          dispatch(
            setFormPopup({ url: data?.request?.type, status: "hide" })
          );
        }
      }
      if (data?.request?.action === "update") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(updateGradeType(data?.data));
          dispatch(
            setFormPopup({ url: data?.request?.type, status: "hide" })
          );
        }
      }
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setGradeTypeList(data?.data));
        }
        if (data?.status === false) {
          dispatch(setGradeTypeList({ items: [], totalCount: 0, optionList: [] }));
        }
      }
      if (data?.request?.action === "delete") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(deleteGradeType(data?.request?.payload));
          dispatch(WarnFormSetFunctions({ status: "hide" }));
        }
      }
      if (data?.request?.action === "updateStatus") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(updateGradeType(data?.data));
        }
      }
      break;
    case "deliveryByService": {
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(addDeliveryByServiceType(data?.data));
          dispatch(
            setFormPopup({ url: data?.request?.type, status: "hide" })
          );
        }
      }
      if (data?.request?.action === "update") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(updateDeliveryByServiceType(data?.data));
          dispatch(
            setFormPopup({ url: data?.request?.type, status: "hide" })
          );
        }
      }
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setDeliveryByServiceTypeList(data?.data));
        }
        if (data?.status === false) {
          dispatch(setDeliveryByServiceTypeList({ items: [], totalCount: 0, optionList: [] }));
        }
      }
      if (data?.request?.action === "delete") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(deleteDeliveryByServiceType(data?.request?.payload));
          dispatch(WarnFormSetFunctions({ status: "hide" }));
        }
      }
      if (data?.request?.action === "updateStatus") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(updateDeliveryByServiceType(data?.data));
        }
      }
      break;
    }
    case "coatingService":
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(addCoating(data?.data));
          dispatch(
            setFormPopup({ url: data?.request?.type, status: "hide" })
          );
        }
      }
      if (data?.request?.action === "update") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(updateCoating(data?.data));

          dispatch(
            setFormPopup({ url: data?.request?.type, status: "hide" })
          );
        }
      }
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setCoatingList(data?.data));
        }
        if (data?.status === false) {
          dispatch(setCoatingList({ items: [], totalCount: 0 }));
        }
      }
      if (data?.request?.action === "delete") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(deleteCoating(data?.request?.payload));
          dispatch(WarnFormSetFunctions({ status: "hide" }));
        }
      }
      if (data?.request?.action === "updateStatus") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(updateCoating(data?.data));
        }
      }
      break;
    case "taxService":
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(addTax(data?.data));
          dispatch(
            setFormPopup({ url: data?.request?.type, status: "hide" })
          );
        }
      }
      if (data?.request?.action === "update") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(updateTax(data?.data));

          dispatch(
            setFormPopup({ url: data?.request?.type, status: "hide" })
          );
        }
      }
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setTaxList(data?.data));
        }
        if (data?.status === false) {
          dispatch(setTaxList({ items: [], totalCount: 0, optionList: [] }));
        }
      }
      if (data?.request?.action === "delete") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(deleteTax(data?.request?.payload));
          dispatch(WarnFormSetFunctions({ status: "hide" }));
        }
      }
      if (data?.request?.action === "updateStatus") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(updateTax(data?.data));
        }
      }
      break;
    case "drawingStatusService":
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(addStatusOfDrawing(data?.data));
          dispatch(
            setFormPopup({ url: data?.request?.type, status: "hide" })
          );
        }
      }
      if (data?.request?.action === "update") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(updateStatusOfDrawing(data?.data));

          dispatch(
            setFormPopup({ url: data?.request?.type, status: "hide" })
          );
        }
      }
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setStatusOfDrawingList(data?.data));
        }
        if (data?.status === false) {
          dispatch(setStatusOfDrawingList({ items: [], totalCount: 0 }));
        }
      }
      if (data?.request?.action === "delete") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(deleteStatusOfDrawing(data?.request?.payload));
          dispatch(WarnFormSetFunctions({ status: "hide" }));
        }
      }
      if (data?.request?.action === "updateStatus") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(updateStatusOfDrawing(data?.data));
        }
      }
      break;

    case "inquiryService": {
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          toast.success(data?.msg);
          navigate(App_url.link.REQUEST_FOR_QUOTATION_URL);
        }
      }
      if (data?.request?.action === "update") {
        if (data?.status === true) {
          toast.success(data?.msg);
          navigate(App_url.link.REQUEST_FOR_QUOTATION_URL);
        }
      }
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setReqQuotationList(data?.data));
        }
        if (data?.status === false) {
          dispatch(setReqQuotationList({ items: [], totalCount: 0 }));
        }
      }
      if (data?.request?.action === "delete") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(deleteReqQuotation(data?.request?.payload));
          dispatch(WarnFormSetFunctions({ status: "hide" }));
        }
      }
      if (data?.request?.action === "archive") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(deleteReqQuotation(data?.request?.payload));
          dispatch(WarnFormSetFunctions({ status: "hide" }));
        }
      }
      if (data?.request?.action === "recover") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(deleteReqQuotation(data?.request?.payload));
          dispatch(WarnFormSetFunctions({ status: "hide" }));
        }
      }
      if (data?.request?.action === "supplier_sale") {
        if (data?.status === true) {
          toast.success(data?.msg);
        } else {
          toast.success(data?.msg);
        }
      }
      if (data?.request?.action === "sale") {
        if (data?.status === true) {
          dispatch(setStoreInquirySupplierSale(data?.data));
        } else {
          dispatch(setStoreInquirySupplierSale());
        }
      }
      if (data?.request?.action === "get") {

        if (data?.request?.demo?.request_for == "info") {
          if (data?.status === true) {
            dispatch(setStoreInquiryItemDetails(data?.data));
          } else {
            dispatch(setStoreInquiryItemDetails());
          }
        } else if (data?.request?.demo?.request_for == "sales_order") {
          if (data?.status === true) {
            dispatch(setStoreInquirySaleDetails(data?.data));
          } else {
            dispatch(setStoreInquirySaleDetails());
          }
        } else if (data?.request?.demo?.request_for == "toggle") {
          if (data?.status === true) {
            dispatch(setStoreInquiryDetails(data?.data));

          } else {
            dispatch(setStoreInquiryDetails());
          }

        } else if (data?.status === true) {
          dispatch(getReqQuotation(data?.data));
        }
      }
      if (data?.request?.action === "supplier_list") {
        if (data?.status === true) {
          dispatch(setStoreInquirySupplierList(data?.data));
        }
        if (data?.status === false) {
          dispatch(setStoreInquirySupplierList({ items: [], totalCount: 0 }));
        }
      }
      if (data?.request?.action === "supplier_price") {
        if (data?.status === true) {
          toast.success(data?.msg);
        }
      }
      if (data?.request?.action === "supplier") {
        if (data?.status === true) {
          dispatch(setFormPopup({ status: "hide" }));
          toast.success(data?.msg);
        }
      }
      break;
    }
    case "inquirySaleService": {
      if (data?.request?.action === "getTaxRate") {
        if (data?.status === true) {
          dispatch(setStoreTaxRate(data?.data?.rate));
        } else {
          dispatch(setStoreTaxRate());
        }
      }
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setSaleOrderList(data?.data));
        }
      }
      if (data?.request?.action === "rollback") {
        if (data?.status === true) {
          if (data?.request?.demo?.order_type === "PO")
            dispatch(deleteScheduledDelivery(data?.data?.parent_id));
          if (data?.request?.demo?.order_type === "BOL")
            dispatch(deleteBOL(data?.data?.parent_id));
        }
      }
      if (data?.request?.action === "pdf") {
        if (data?.status === true) {
          if (data?.request?.demo?.request_for == "view") {
            // dispatch(
            //   setFormPopup({
            //     status: "add",
            //     name: "Sale Order Email",
            //     url: data?.request?.payload,
            //   })
            // );
            openPreview(data?.data?.blob, dispatch, data?.request?.demo?.filename);
          } else {
            // dispatch(
            //   setFormPopup({
            //     status: "add",
            //     name: "Sale Order Email",
            //     url: data?.request?.payload,
            //   })
            // );
            openPreview(data?.data?.blob, dispatch, data?.request?.demo?.filename);
          }
        }
      }
      if (data?.request?.action === "get") {
        if (data?.request?.demo?.request_for == "invoice_resend") {
          dispatch(setInquiryDetail(data?.data));
       dispatch(setFormPopup({ status: "add", name: "Edit Invoice", url: data?.data }));
        }
        else if (data?.request?.demo?.request_for == "sales_order") {
          if (data?.status === true) {
            dispatch(setStoreInquirySaleData(data?.data))
          } else {
            dispatch(setStoreInquirySaleData())
          }
        } else if (data?.status === true) {
          dispatch(setInquiryDetail(data?.data));
        }
      }
      if (data?.request?.action === "list" && data?.request?.payload?.status === "PO") {
        if (data?.status === true) {
          dispatch(setPOList(data?.data));
        }
      }
      if (data?.request?.action === "list" && data?.request?.payload?.status === "SCHEDULED") {
        if (data?.status === true) {
          dispatch(setStoreScheduledList(data?.data));
        }
      }
      if (data?.request?.action === "list" && data?.request?.payload?.status === "DELIVERED") {
        if (data?.status === true) {
          dispatch(setDeliveredList(data?.data));
        } else {
          dispatch(setDeliveredList({ items: [], totalCount: 0, optionList: [], }));
        }
      }
      if (data?.request?.action === "schedule") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(setFormPopup({ url: data?.request?.type, status: "hide" }))
          dispatch(deleteScheduledDelivery(data?.data?.id));
          navigate(App_url.link.DISTRIBUTION_BOL)
        }
        if (data?.status === false && data?.msg === "Schedule delivery not added to padlet") {
          toast.error("Schedule delivery not added to padlet !");
          dispatch(setFormPopup({ url: data?.request?.type, status: "hide" }))
          navigate(App_url.link.DISTRIBUTION_BOL)
        }
      }
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(addSaleOrder(data?.data));
          dispatch(setFormPopup({ status: "hide" }));

          navigate(App_url.link.SALE_ORDER_URL)
        }
      }
      if (data?.request?.action === "update") {
        if (data?.status === true) {
          toast.success(data?.msg);

          navigate(App_url.link.SALE_ORDER_URL)
        }
      }
      if (data?.request?.action === "accept") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(changeSaleOrderStatus({ id: data?.data?.id, status: 'ACCEPTED' }));
        }
      }
      if (data?.request?.action === "reject") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(changeSaleOrderStatus({ id: data?.data?.id, status: 'REJECTED' }));
        }
      }
      if (data?.request?.action === "po") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(deleteSaleOrder(data?.data?.id));
          dispatch(setFormPopup({ status: "hide" }));
          navigate(App_url.link.PURCHASE_ORDER_URL)
        }
      }
      if (data?.request?.action === "archive") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(deleteSaleOrder(data?.data?.id));
        }
      }
      if (data?.request?.action === "recover") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(deleteSaleOrder(data?.data?.id));
        }
      }
      if (data?.request?.action === "deliver") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(setFormPopup({ status: "hide" }));
          navigate(App_url.link.DELIVERED_URL)
          // getData(
          //   `${App_url.link.ENDPOINT_LINKS.INVOICE_DOWNLOAD}/${data?.data?.invoice_id}`,
          //   access_token, dispatch
          // )
          navigate(App_url.link.DELIVERED_URL)

        }
      }
      if (data?.request?.action === "schedulePadlet") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(setAddPadlet(data?.data));
        }
      }
      break;
    }
    case "apiSetupService": {
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setStoreCredentialList(data?.data));
        }
      }
      if (data?.request?.action === "update") {
        if (data?.status === true) {
          toast.success(data?.msg);

          if (data?.request?.demo?.redirect) {
            const payload = {
              clientId: data?.request?.demo?.redirect?.quick_book_id,
              clientSecret: data?.request?.demo?.redirect?.quick_book_secret,
              environment: data?.request?.demo?.redirect?.quick_book_env,
            }
            await postData(App_url.link.ENDPOINT_LINKS.INTUIT_AUTH, payload, access_token).then((res) => {
              var parameters = "location=1,width=800,height=650";
              parameters += ",left=" + (window.screen.width - 800) / 2 + ",top=" + (window.screen.height - 650) / 2;
              window.open(res?.data, 'connectPopup', parameters);
            })
          }
        }
      }
      break;
    }
   
    case "emailSetupService": {
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setStoreEmailSetupList(data?.data));
        } else {
          dispatch(setStoreEmailSetupList({ emailSetupList: { serializedItems: [], optionsList: [], totalCount: 0 } }));
        }
      }
      if (data?.request?.action === "update") {
        if (data?.status === true) {
          // window.open(`https://kas.appristine.in/api/intuit/auth${}`)
          toast.success(data?.msg);
        }
      }
      break;
    }
    case "emailTemplateService": {
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setStoreEmailTemplateList(data?.data));
        } else {
          dispatch(setStoreEmailTemplateList({ items: [], totalCount: 0 }));
        }
      }
      if (data?.request?.action === "update") {
        if (data?.status === true) {
          toast.success(data?.msg);
        }
      }
      if (data?.request?.action === "get") {
        if (data?.status === true) {
          dispatch(setEmailDetail(data?.data));
        }
      }
      break;
    }
    case "intuitService": {
      if (data?.request?.action === "items") {
        if (data?.status === true) {
          dispatch(setIntuitList(data?.data));
        }
        if (data?.status === false) {
          dispatch(setIntuitList({
            Item: [],
            maxResults: 0,
            startPosition: 0,
            optionList: []
          }));
        }
      }
      if (data?.request?.action === "taxRate") {
        if (data?.status === true) {
          dispatch(setIntuitTaxList(data?.data));
        }
      }
      if (data?.request?.action === "terms") {
        if (data?.status === true) {
          dispatch(setIntuitTerms(data?.data));
        }
        if (data?.status === false) {
          dispatch(setIntuitTerms({ maxResults: 0, optionList: [], startPosition: 0, Term: [] }));
        }
      }
      break
    }
    case "customerService": {
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setStoreCustomersList(data?.data));
        } else {
          dispatch(setStoreCustomersList({ parsedItems: [], totalCount: 0, optionsList: [] }));
        }
      }
      if (data?.request?.action === "get") {
        if (data?.status === true) {
          dispatch(setStoreCustomersDetails(data?.data));
        }
      }
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          toast.success(data?.msg);
        }
      }
      if (data?.request?.action === "update") {
        if (data?.status === true) {
          toast.success(data?.msg);
        }
      }

      break;

    }
    case "inventoryService": {
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setStoreInventoryList(data?.data));
        } else {
          dispatch(setStoreInventoryList({ items: [], totalCount: 0, optionList: [] }));
        }
      }
      if (data?.request?.action === "stock") {
        if (data?.status === true) {
          if (data?.request?.demo?.search_for) {
            dispatch(setStorePricingInventoryList(data?.data));
          } else {
            dispatch(setStoreInventoryStockList(data?.data));
          }
        } else {
          if (data?.request?.demo?.search_for) {
            dispatch(setStorePricingInventoryList({ items: [], totalCount: 0, optionList: [] }));
          } else {
            dispatch(setStoreInventoryStockList({ items: [], totalCount: 0, optionList: [] }));
          }
        }
      }
      if (data?.request?.action === "get") {
        if (data?.status === true) {
          dispatch(setStoreInventoryDetails(data?.data));
        }
      }
      if (data?.request?.action === "create" || data?.request?.action === "edit") {
        if (data?.status === true) {
          toast.success(data?.msg);
        }
      }
      if (data?.request?.action === "update") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(setStoreUpdateInventoryStock(data?.data))
        }
      }

      break;
    }
    case "checklistService":
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(addChecklistQuestion(data?.data));
          dispatch(
            setFormPopup({ url: data?.request?.type, status: "hide" })
          );
        }
      }
      if (data?.request?.action === "update") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(updateChecklistQuestion(data?.data));
          dispatch(
            setFormPopup({ url: data?.request?.type, status: "hide" })
          );
        }
      }
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setChecklistQuestionList(data?.data));
        }
      }
      if (data?.request?.action === "delete") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(deleteChecklistQuestion(data?.request?.payload));
          dispatch(WarnFormSetFunctions({ status: "hide" }));
        }
      }
      break;

    case "fabricationService": {
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          toast.success(data?.msg);
        }
      }
      if (data?.request?.action === "update") {
        if (data?.status === true) {
          toast.success(data?.msg);
        }
      }
      if (data?.request?.action === "list") {
        if (data?.request?.payload?.status === "SCHEDULED") {
          if (data?.status === true) {
            dispatch(setStoreFabricationScheduleList(data?.data));
          } else {
            dispatch(setStoreFabricationScheduleList({ items: [], totalCount: 0, optionList: [] }));
          }
        } else if (data?.request?.payload?.status === "DELIVERED") {
          if (data?.status === true) {
            dispatch(setStoreFabricationDeliveredList(data?.data));
          } else {
            dispatch(setStoreFabricationDeliveredList({ items: [], totalCount: 0, optionList: [] }));
          }
        } else if (data?.status === true) {
          dispatch(setStoreFabricationList(data?.data));
        } else {
          dispatch(setStoreFabricationList({ items: [], totalCount: 0, optionList: [] }));
        }
      }
      if (data?.request?.action === "get") {
        if (data?.status === true) {
          dispatch(setStoreFabricationDetails(data?.data));
        } else {
          dispatch(setStoreFabricationDetails(null));
        }
      }
      if (data?.request?.action === "get") {
        if (data?.status === true) {
          dispatch(setInquiryDetail(data?.data));
        }
      }
      break;
    }
    case "shopService": {

      if (data?.request?.action === "create") {
        if (data?.status === true) {
          toast.success(data?.msg);
          navigate(App_url.link.SHOP_SUPPLY_URL)
        }
      }
      if (data?.request?.action === "update") {
        if (data?.status === true) {
          toast.success(data?.msg);
          navigate(App_url.link.SHOP_SUPPLY_URL)
        }
      }
      if (data?.request?.action === "list") {
        if (data?.request?.demo?.request_for == "projectHistory") {
          if (data?.status === true) {
            dispatch(setStoreProjectShopSupply(data?.data));
          } else {
            dispatch(setStoreProjectShopSupply({ items: [], totalCount: 0, optionList: [] }));
          }
          break;
        }
        if (data?.request?.payload?.status === "SCHEDULED") {
          if (data?.status === true) {
            dispatch(setStoreShopScheduleList(data?.data));
          } else {
            dispatch(setStoreShopScheduleList({ items: [], totalCount: 0, optionList: [] }));
          }
        } else if (data?.request?.payload?.status === "DELIVERED") {
          if (data?.status === true) {
            dispatch(setStoreShopDeliveredList(data?.data));
          } else {
            dispatch(setStoreShopDeliveredList({ items: [], totalCount: 0, optionList: [] }));
          }
        } else if (data?.status === true) {
          dispatch(setStoreShopList(data?.data));
        } else {
          dispatch(setStoreShopList({ items: [], totalCount: 0, optionList: [] }));
        }
      }
      if (data?.request?.action === "get") {
        if (data?.status === true) {
          dispatch(setStoreShopDetails(data?.data));
        } else {
          dispatch(setStoreShopDetails(null));
        }
      }
      break;
    }

    case "fabricationScheduleService": {
      if (data?.request?.action === "schedule") {
        if (data?.status === true) {
          toast.success(data?.msg);
          navigate(App_url.link.FABRICATION_BOL)
          dispatch(setFormPopup({ status: "hide" }));
        }
      }
      if (data?.request?.action === "deliver") {
        if (data?.status === true) {
          toast.success(data?.msg);
          navigate(App_url.link.FABRICATION_DELIVERED)
          dispatch(setFormPopup({ status: "hide" }));
        }
      }
      break;
    }

    case "shopScheduleService": {
      if (data?.request?.action === "schedule") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(setFormPopup({ status: "hide" }));
          navigate(App_url.link.SHOP_SUPPLY_BOL)
        }
      }
      if (data?.request?.action === "deliver") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(setFormPopup({ status: "hide" }));
          navigate(App_url.link.SHOP_SUPPLY_DELIVERED)

        }
      }
      
      if (data?.request?.action === "rollback") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(deleteShopSupplyDelivery(data?.data?.parent_id));
        }
      }

      if (data?.request?.action === "editInvoice") {
        if (data?.status === true)
        toast.success(data?.msg)
      }
      break;
    }

    case "stockScheduleService": {
      if (data?.request?.action === "schedule") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(setFormPopup({ status: "hide" }));
          navigate(App_url.link.STOCK_BILL_OF_LADING)
        }
      }
      if (data?.request?.action === "deliver") {
        if (data?.status === true) {
          toast.success(data?.msg);
          navigate(App_url.link.STOCK_DELIVERED)
          dispatch(setFormPopup({ status: "hide" }));
          // getData(
          //   `${App_url.link.ENDPOINT_LINKS.INVOICE_DOWNLOAD}/${data?.data?.invoice_id}`,
          //   access_token, dispatch
          // )
        }
      }
      break;
    }
    case "stockService": {
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          toast.success(data?.msg);
          navigate(App_url.link.STOCK_ORDER)
        }
      }
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setStockSupplyList(data?.data));
        }
      }
      if (data?.request?.action === "update") {
        if (data?.status === true) {
          toast.success(data?.msg)
          navigate(App_url.link.STOCK_ORDER)
        }
      }
      break;
    }

    case "proposalService": {
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          toast.success(data?.msg);
          if (data?.request?.payload?.is_draft)
            navigate(App_url.link.PROPOSAL_URL)
          else
            navigate(`${App_url.link.PREVIEW_SEND_PROPOSAL_URL}/${data?.data?.id}`, { state: { data: data?.data } })
          // navigate(`${App_url.link.ADD_PROPOSAL_AGGREEMENT_URL}/${data?.data?.id}`,{ state: { data: data?.data } })
        }
      }
      if (data?.request?.action === "upload") {
        if (data?.status === true) {
          // dispatch(setProposalList(data?.data));
          dispatch(
            setFormPopup({
              status: "hide",
              name: "Upload Document",

            })
          );
          toast.success(data?.msg);
        }
      }
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setProposalList(data?.data));
        }
      }
      if (data?.request?.action === "accept") {
        if (data?.status === true) {
          dispatch(setProposalStatus(data?.data));
        }
      }

      if (data?.request?.action === "get") {
        if (data?.status === true) {
          dispatch(setProposalDetails(data?.data))
        }
      }

      if (data?.request?.action === "sent") {
        if (data?.status === true) {
          toast.success(data?.msg);
          navigate(App_url.link.PROPOSAL_URL)
        }
      }

      if (data?.request?.action === "update") {
        if (data?.status === true) {
          toast.success(data?.msg);
          if (data?.request?.payload?.is_draft)
            navigate(App_url.link.PROPOSAL_URL)
          else
            navigate(`${App_url.link.PREVIEW_SEND_PROPOSAL_URL}/${data?.data?.id}`, { state: { data: data?.data } })
          // navigate(`${App_url.link.ADD_PROPOSAL_AGGREEMENT_URL}/${data?.data?.id}`,{ state: { data: data?.data } })
        }
      }

      if (data?.request?.action === "delete" || data?.request?.action === "archive") {
        if (data?.status === true) {
          dispatch(deleteProposal(data?.request?.payload));
          toast.success(data?.msg);
        }
      }
      break;
    }

    case "logisticService": {
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setLogisticList(data?.data));
        }
      }
      if (data?.request?.action === "location") {
        if (data?.status === true) {
          dispatch(setLogisticLocationServiceTypeList(data?.data));
        }
      }
      break;
    }

    case "frontDashboardService": {
      if (data?.request?.action === "get") {
        if (data?.status === true) {
          dispatch(setDashboardData(data?.data))
        }
      }
      break;
    }

    case "productLengthService": {
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(addProductLength(data?.data));
          dispatch(
            setFormPopup({ url: data?.request?.type, status: "hide" })
          );
        }
      }
      if (data?.request?.action === "update") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(updateProductLength(data?.data));

          dispatch(
            setFormPopup({ url: data?.request?.type, status: "hide" })
          );
        }
      }
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setProductLengthList(data?.data));
        }
        if (data?.status === false) {
          dispatch(setProductLengthList({ items: [], totalCount: 0, optionList: [] }));
        }
      }
      if (data?.request?.action === "delete") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(deleteProductLength(data?.request?.payload));
          dispatch(WarnFormSetFunctions({ status: "hide" }));
        }
      }
      if (data?.request?.action === "updateStatus") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(updateProductLength(data?.data));
        }
      }
      break;

    }
    case "productUnitService": {
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(addUnitService(data?.data));
          dispatch(setFormPopup({ url: data?.request?.type, status: "hide" }));
        }
      }
      if (data?.request?.action === "update") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(updateUnitService(data?.data));
          dispatch(setFormPopup({ url: data?.request?.type, status: "hide" }));
        }
      }
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setUnitServiceList(data?.data));
        }
        if (data?.status === false) {
          dispatch(setUnitServiceList({ items: [], totalCount: 0, optionList: [] }));
        }
      }
      if (data?.request?.action === "delete") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(deleteUnitService(data?.request?.payload));
          dispatch(WarnFormSetFunctions({ status: "hide" }));
        }
      }
      if (data?.request?.action === "updateStatus") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(updateUnitService(data?.data));
        }
      }
      break;
    }

    case "backOfficeProjectService":{
      if (data?.request?.action === "create"){
        toast.success(data?.msg);
        if (data?.status === true) {
       navigate(App_url.link.PROJECT_URL)
      dispatch(
        setFormPopup({
          status: "add",
          name: `Add Assign Project`,
          url: { project_id: [data?.data] }
        })
      )}}
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setStoreProjectsList(data?.data))
        } else {
          dispatch(setStoreProjectsList({ items: [], totalCount: 0, optionsList: [] }))
        }
      }
      if (data?.request?.action === "update") {
        toast.success(data?.msg);
        navigate(`${App_url.link.ASSIGN_PROJECT_URL}/${data?.data?.id}`)
        dispatch(
          setFormPopup({
            status: "add",
            name: `Add Assign Project`,
            url: { project_id: [data?.data] }
          })
        )

      }
      if (data?.request?.action === "assign") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(setAssignProjectDetails(data?.data));
          dispatch(setFormPopup({ url: data?.request?.type, status: "hide" }));
        }
      }
      break;
    }
    case "projectService": {
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          if (user_data.user.user_type === "front_office") {
            toast.success(data?.msg);
            dispatch(setAddProjectsDetails(data?.data));
            // navigate(`${App_url.link.EDIT_PROJECT_ESCALATION_URL}/${data?.data?.id}`)
          }
        }

      }
      if (data?.request?.action === "update") {

        if (data?.status === true) {
          toast.success(data?.msg);
          if (user_data.user.user_type === "front_office") {
            dispatch(setAddProjectsDetails(data?.data));
            // navigate(`${App_url.link.EDIT_PROJECT_ESCALATION_URL}/${data?.data?.id}`)
          }
          
        }
      }
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setStoreProjectsList(data?.data))
        } else {
          dispatch(setStoreProjectsList({ items: [], totalCount: 0, optionsList: [] }))
        }
      }
      if (data?.request?.action === "updatePricing") {
        if (data?.status === true) {
          toast.success(data?.msg);
          console.log("data?.request?.demo", data?.request?.demo)
          // if(data?.request?.demo?.navigate){
          //   navigate(`${App_url.link.ADD_ESCALATION_URL}/${data?.request?.payload?.id}`)
          // }else{
          //   navigate(App_url.link.PROJECT_URL)
          // }
        }
      }
      if (data?.request?.action === "updateEscalation") {
        if (data?.status === true) {
          toast.success(data?.msg);
          console.log("data?.request?.demo", data?.request?.demo)
          // if(data?.request?.demo?.navigate){
          //   navigate(`${App_url.link.ADD_ESCALATION_URL}/${data?.request?.payload?.id}`)
          // }else{
          //   navigate(App_url.link.PROJECT_URL)
          // }
        }
      }
      if (data?.request?.action === "archive" || data?.request?.action === "recover") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(setDeleteProjectDetails(data?.request?.payload));
        }
      }
      if (data?.request?.action === "get") {
        if (data?.status === true) {
          if (data?.request?.demo?.request_for == "projectHistory") {
            dispatch(setStoreProjectsDetails(data?.data));
          } else {
            dispatch(setStoreFabricationProjectsDetails(data?.data));
          }
        } else {
          if (data?.request?.demo?.request_for == "projectHistory") {
            dispatch(setStoreProjectsDetails(null));
          } else {
            dispatch(setStoreFabricationProjectsDetails(null));
          }
        }
      }
      
      break;
    }
   
    case "packageService": {
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(setAddPackagesDetails(data?.data));
          navigate(`${App_url.link.DRAWING_TRACKER_URL.replace(':id', data.data.project_id)}`);
          dispatch(setFormPopup({ url: data?.request?.type, status: "hide" }));
        }
      }
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setStorePackagesList(data?.data))
        }
      }

      break;
    }
    case "drawingService": {
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          toast.success(data?.msg);
        }
      }
      if (data?.request?.action === "update") {
        if (data?.status === true) {
          toast.success(data?.msg);
        } else {
          toast.error(data?.msg)
        }
      }
      if (data?.request?.action === "delete") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(setDeletePackageDrawing(data?.request?.payload));
          dispatch(WarnFormSetFunctions({ status: "hide" }));
        } else if (data?.status === false) {
          toast.error(data?.msg);
        }
      }
      if (data?.request?.action == "list") {
        if (data?.status === true) {
          if (data?.request?.payload?.project_id && data?.request?.payload?.package_id) {
            dispatch(setStorePackageDrawingList(data?.data));
          } else {
            dispatch(setStorePackageDrawingList(data?.data));
          }
        } else {
          if (data?.request?.payload?.project_id && data?.request?.payload?.package_id) {
            dispatch(setStorePackageDrawingList({ items: [], totalCount: 0, optionsList: [] }));
          } else {
            dispatch(setStorePackageDrawingList({ items: [], totalCount: 0, optionsList: [] }));
          }
        }
      }


      break;
    }
    case "saleService": {
      if (data?.request?.action === "sendBOL") {
        if (data?.status === true) {
          toast.success(data?.msg);
        }
      }
      if (data?.request?.action === "editInvoice") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(setUpdateDeliveredList({...data?.data,invoice: JSON.parse(data?.data?.invoice)}))
          dispatch(setFormPopup({status:'hide'}));
        }
      }
      break;
    }
    case "listLogService": {
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(setAddListlogDetails(data?.data));
          navigate(`${App_url.link.LIST_LOG_URL.replace(':id', data.data.project_id)}`);
          dispatch(setFormPopup({ url: data?.request?.type, status: "hide" }));
        }
      }
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setStoreListLogList(data?.data))
        }
      }
      if (data?.request?.action === "delete") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(setDeleteListLog(data?.request?.payload));
          dispatch(WarnFormSetFunctions({ status: "hide" }));
        }
      }
      if (data?.request?.action === "update") {
        if (data?.status === true) {
          toast.success(data?.msg);
          navigate(`${App_url.link.LIST_LOG_URL.replace(':id', data.data.project_id)}`);
          dispatch(setFormPopup({ url: data?.request?.type, status: "hide" }));
        }
      }

      break;
    }
    case "revisionHistoryService": {
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(setAddRevisionHistoryDetails(data?.data));
          // navigate(`${App_url.link.LIST_LOG_URL.replace(':id', data.data.project_id)}`);
          // dispatch(setFormPopup({ url: data?.request?.type, status: "hide" }));
        }
      }
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setStoreRevisionHistoryList(data?.data))
        }
      }
      if (data?.request?.action === "delete") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(setDeleteRevisionHistory(data?.request?.payload));
        }
      }
      if(data?.request?.action === "update"){
        if(data?.status === true){
          toast.success(data?.msg);
          dispatch(setFormPopup({status: "hide" }));
        }else{
          toast.error(data?.msg);
        }
      }

      break;
    }
    case "drawingVersionService": {
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(setAddListlogDetails(data?.data));
          if(clearForm?.name){
            dispatch(setFormPopup({
                status: "modal",
                name: "Drawing Details",
                url: {
                    ...clearForm.url,
                    file_data: data?.data?.file_date,
                    url: data?.data
                },
            }));
          }
        }
      }
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setStoreDrawingVersionList(data?.data))
        }
      }
      if (data?.request?.action === "delete") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(setDeleteDrawingVersion(data?.request?.payload));
        }
      }

      break;
    }
    case "changeOrderService": {
      if (data?.request?.action === "create") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(setAddChangeOrder(data?.data));
          dispatch(setFormPopup({ status: "hide" }));
        }
      }
      if (data?.request?.action === "list") {
        if (data?.status === true) {
          dispatch(setChangeOrderList(data?.data))
        }
      }
      if (data?.request?.action === "delete") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(setDeleteChangeOrder(data?.request?.payload));
          dispatch(WarnFormSetFunctions({ status: "hide" }));
        }
      }

      break;
    }
    case "ChatMessageService": {
      if (data?.request?.action === "sendAllMessage") {
        if (data?.status === true) {
          if (ChannelDetails && data?.request?.demo?.group_id === ChannelDetails?.id) {
            const joinPayload = {
                type: 'ChatMessageService',
                action: 'joinGroups',
                payload: {
                    userId: ChannelDetails?.id
                },
            };
            dispatch(setStoreUpdateUserChat({ id: ChannelDetails?.id, group_id: data?.data?.group_id }));
            dispatch(setStoreChannelDetails({
              ...ChannelDetails,
              group_id: data?.data?.group_id,
              user_id: ChannelDetails?.user_id,
            }));
            navigate(`${App_url.link.CHAT_URL}/${data?.data?.group_id}`);
            send(joinPayload)
          }
          if(data?.request?.demo?.group_id === ChannelDetails?.group_id){
            // dispatch(setStoreUpdateChatCount(data?.data));
            dispatch(setStoreUpdateChatUsers({
              data: {
                  ...ChannelDetails,
                  message_count: 0,
                  last_message: data?.data,
                  message: data?.data?.message,
                  created_at: data?.data?.created_at,
                  updated_at: data?.data?.updated_at,
              },
              group_type: ChannelDetails?.group_type,
            }))
          }else{
            dispatch(setStoreUpdateChatCount({
              data: data?.data,
              group_type: data?.data?.group_type,
            }));
          }
          if(data?.data?.group_type  == "single" || data?.data?.group_type  == "group" || data?.data?.group_type  == "project"){
            dispatch(setStoreMessage({
              data: data?.data,
              group_id: data?.data?.group_id
            }));
          }
          dispatch(setAddNotificationList(data?.data))
        }
      }
      if (response?.status) {
          if (response?.request?.action === "getMessages") {
              const { data } = response;
              dispatch(setStoreMessageList({
                data: data,
                group_id: response?.request?.payload?.group_id
              }))
          }
      } else {
          // console.error(response?.msg);
      }
      if(data?.request?.action === "listing"){
        if(data?.status === true){
          console.log("listingdata::",data.data?.filteredGroups)
          dispatch(setFilteredGroups(data?.data?.filteredGroups))
          if(internal_user?.items?.length){
            dispatch(setStoreUpdateSingleChatUsersList({users_list: internal_user?.items, current_user_id: user?.id}));
          }
        }
      }
      break;
    }
    case "ChatGroupService": {
      if (data?.request?.action === "createOrUpdateGroupChat") {
        if (data?.status === true) {
          toast.success(data?.msg);
          dispatch(setStoreGroupAdd(data?.data));
          dispatch(setFormPopup({ status: "hide" }));
        }
      }
      if (data?.request?.action === "createOrUpdateProjectChatGroup") {
        if (data?.status === true) {
          toast.success(data?.msg);
          // dispatch(setStoreGroupAdd(data?.data));
          dispatch(setFormPopup({ status: "hide" }));
        }
      }
      if(data?.request?.action === "updateGroupName"){
        if(data?.status === true){
          toast.success(data?.msg);
          dispatch(setFormPopup({ status: "hide" }));
        }
      }
      if(data?.request?.action === "removeUser"){
        if(data?.status === true){
          toast.success(data?.msg);
          console.log("data::",data)
          dispatch(setRemoveUserFromGroup(data?.request?.payload));
          // dispatch(setFormPopup({ status: "hide" }));
        }
      }
      if(data?.request?.action === "addUser"){
        if(data?.status === true){
          toast.success(data?.msg);
          dispatch(setAddUserToGroup(data?.data))
          dispatch(setFormPopup({ status: "hide" }));
        }
      }
      if(data?.request?.action==="getGroup"){
        if(data?.status===true){
          dispatch(setGroupDetails(data?.data?.group));
        }
      }
      break;
    }


    case "searchService": {
      if (data?.request?.action === "list" ) {
        if (data?.status === true) {
          dispatch(setSearchList(data?.data))
        }

      }
      if (data?.request?.action === "createCombinations" ) {
        if (data?.status === true) {
          dispatch(setSearchList({items: data?.data?.map(i=>({name:i})),totalCount:data?.data?.length}))
        }

      }
      if (data?.status === false) {
        dispatch(setSearchList({items:[],totalCount:0,optionsList:[]}))
      }
      break;
    }
    case "saleOrderService": {
      if (data?.request?.action === "create"||data?.request?.action === "update") {
        if (data?.status === true) {
          toast.success(data?.msg);
          navigate(App_url.link.SALE_ORDER_MODULE)
        }
      }
      if (data?.request?.action === "list") {
        if (data?.status === true) {
        dispatch(setSaleOrderModuleList(data?.data))
        }
      }
      if (data?.request?.action === "accept"||data?.request?.action === "archive"||data?.request?.action === "recover" ) {
        if (data?.status) {
          toast.success(data?.data)
        dispatch(setDeleteOrder(data?.data))
        }
      }
      if (data?.request?.action === "reject" ) {
        if (data?.status === true) {
          toast.success(data?.data)
        dispatch(setSaleUpdateStatus(data?.data))
        }
      }
      if (data?.request?.action === "pdf" ) {
        if (data?.status === true) {
          openPreview(data?.data?.blob, dispatch, data?.request?.demo?.filename);
        }
      }
      break;
    }
    case "purchaseOrderService": {
      if (data?.request?.action === "create"||data?.request?.action === "update") {
        if (data?.status === true) {
          toast.success(data?.msg);
          navigate(App_url.link.PURCHASE_ORDER_MODULE)
        }
      }
      if (data?.request?.action === "list") {
        if (data?.status === true) {
        dispatch(setPurchaseOrderModuleList(data?.data))
        }
      }
      if (data?.request?.action === "accept"||data?.request?.action === "archive"||data?.request?.action === "recover" ) {
        if (data?.status) {
          toast.success(data?.data)
        dispatch(setPurchaseDeleteOrder(data?.data))
        }
      }
      if (data?.request?.action === "reject" ) {
        if (data?.status === true) {
          toast.success(data?.data)
        dispatch(setPurchaseUpdateStatus(data?.data))
        }
      }
      if (data?.request?.action === "pdf" ) {
        if (data?.status === true) {
          openPreview(data?.data?.blob, dispatch, data?.request?.demo?.filename);
        }
      }
      break;
    }
    case "inventoryTransferService":{
      if(data?.request?.action == "create"){
        if (data?.status === true) {
          toast.success(data?.msg);
          navigate(App_url.link.STOCK_TRANSFER_URL);
        }
      }
      if(data?.request?.action == "createChild"){
        if (data?.status === true) {
          toast.success(data?.msg);
          navigate(App_url.link.STOCK_TRANSFER_URL);
        }
      }
      if(data?.request?.action == "child"){
        if (data?.status === true) {
          toast.success(data?.msg);
          navigate(App_url.link.STOCK_TRANSFER_URL);
        }
      }
      if(data?.request?.action == "edit"){
        if (data?.status === true) {
          toast.success(data?.msg);
          navigate(App_url.link.STOCK_TRANSFER_URL);
          dispatch(setStoreStockTransferDetails(null));
        }
      }
      if(data?.request?.action == "upload"){
        if (data?.status === true) {
          dispatch(setUpdateStockToggleList(data?.data))
          dispatch(
            setFormPopup({
              status: "hide",
              name: "Upload Document",
            })
          );
          toast.success(data?.msg);
        }
      }
      if (data?.request?.action == "list") {
        if (data?.status === true) {
          dispatch(setStoreStockTransferList(data?.data))
        } else {
          dispatch(setStoreStockTransferList({ items: [], totalCount: 0, optionList: [], product_list:[] }))
        }
      }
      if(data?.request?.action == "list"){
        if(data?.request?.demo?.toggle_request){
          if (data?.status === true) {
            dispatch(setStoreStockToggleList({...data?.data,parent_id:data?.request?.payload?.parent_id }))
          }else{
            dispatch(setStoreStockToggleList({items:[], totalCount:0, optionList:[], product_list:[]}))
          }
        }else{
          if (data?.status === true) {
            dispatch(setStoreStockTransferList(data?.data))
          }else{
            dispatch(setStoreStockTransferList({items:[], totalCount:0, optionList:[], product_list:[]}))
          }
        }
      }
      if(data?.request?.action == "get" && data?.request?.demo?.parent_id){
        if (data?.status === true) {
          dispatch(setStoreStockTransferParent(data?.data))
        }else{
          dispatch(setStoreStockTransferParent(null))
        }
      }else if(data?.request?.action == "get"){
        if (data?.status === true) {
          dispatch(setStoreStockTransferDetails(data?.data))
        } else {
          dispatch(setStoreStockTransferDetails(null))
        }
      }
      if(data?.request?.action == "book"){
        if (data?.status === true) {
          dispatch(setUpdateStockToggleList(data?.data))
          toast.success(data?.msg);
          
        }
        dispatch(setFormPopup({status:'hide'}))

      }
      if(data?.request?.action == "history"){
        if (data?.status === true) {
          dispatch(setStoreInventoryTransferList(data?.data))
        } else {
          dispatch(setStoreInventoryTransferList({ items: [], totalCount: 0, optionList: [] }))
        }
      }
      break;
    }

    case "submissionService":{
      if(data?.request?.action == "create"){
        if (data?.status === true) {
          toast.success(data?.msg);
        }
      }
      break;
    }
    default:
      console.log("Unhandled socket response type:", type);
      break;
  }
})
/* eslint-disable array-callback-return */

import { ActionTypes } from './action';
import { IProjectRes } from "./types";


const initialState: IProjectRes = {
  projectsList: {
    optionsList: [],
    items: [],
    totalCount: 0,
  },
  projectDetails: null,
  fabricationProjectDetails: null,

  packageList: {
    optionsList: [],
    items: [],
    totalCount: 0,
  },

  packageDetails: null,

  assignProjectList: {
    optionsList: [],
    items: [],
    totalCount: 0,
  },

  assignProjectDetails: null,
  packageDrawingList: {
    optionsList: [],
    items: [],
    totalCount: 0,
  },

  listLogList: {
    optionsList: [],
    items: [],
    totalCount: 0,
  },

  listLogDetails: null,

  revisionHistoryList: {
    optionsList: [],
    items: [],
    totalCount: 0,
  },

  revisionHistoryDetails: null,

  drawingVersionList: {
    optionsList: [],
    items: [],
    totalCount: 0,
  },

  drawingVersionDetails: null,
  projectShopSupply:null,
};

const projectsReducers = (state: IProjectRes = initialState, action): IProjectRes => {
  switch (action?.type) {
    case ActionTypes.SET_STORE_PROJECTS_LIST: {
      const optionsList = action?.payload?.items?.map((item, index) => ({
        ...item,
        label: item?.name,
        value: item?.id
      }));
      return {
        ...state,
        projectsList: {
          ...action?.payload,
          optionsList: optionsList,
        },
      };
    }
    case ActionTypes.SET_STORE_PROJECT_DETAILS: {
      const contact_person = [];
      if (action?.payload) {
        action?.payload?.contact_person?.map((item) => {
          contact_person.push({
            ...item,
            label: item?.name,
            value: item?.name,
          })
        })
      }
      const data = action?.payload ? { ...action?.payload, contact_person: contact_person } : null
      return {
        ...state,
        projectDetails: data,
      }
    }
    case ActionTypes.SET_STORE_FABRICATION_PROJECT_DETAILS: {
      const contact_person = [];
      if (action?.payload) {
        action?.payload?.contact_person?.map((item) => {
          contact_person.push({
            ...item,
            label: item?.name,
            value: item?.name,
          })
        })
      }
      const data = action?.payload ? { ...action?.payload, contact_person: contact_person } : null
      return {
        ...state,
        fabricationProjectDetails: data,
      }
    }
    case ActionTypes.SET_ADD_PROJECT_DETAILS: {
      const items = [];
      items.push(action?.payload);
      state?.projectsList.items?.map((item, index) => {
        items.push(item);
      })
      const totalCount = state?.projectsList.totalCount + 1;
      return {
        ...state,
        projectsList: {
          items: items,
          totalCount: totalCount,
        }
      };
    }
    case ActionTypes.SET_DELETE_PROJECT_DETAILS: {
      const updatedItems = state?.projectsList.items.filter(item => item.id !== action.payload.id);
      const updatedTotalCount = state?.projectsList.totalCount - 1;

      return {
        ...state,
        projectsList: {
          ...state?.projectsList,
          items: updatedItems,
          totalCount: updatedTotalCount,
        },
      };
    }
    //project details
    case ActionTypes.SET_PACKAGE_LIST: {
      const optionsList = action?.payload?.items?.map((item) => ({
        ...item,
        label: item?.package_name,
        value: item?.id
      }));
      return {
        ...state,
        packageList: {
          ...state?.packageList,
          ...action?.payload,
          optionsList: optionsList,
        },
      };
    }
    case ActionTypes.SET_ADD_PACKAGE_DETAILS: {
      const items = [];
      items.push(action?.payload);
      state?.packageList?.items?.map((item, index) => {
        items.push(item);
      })
      const totalCount = state?.packageList?.totalCount + 1;
      return {
        ...state,
        packageList: {
          items: items,
          totalCount: totalCount,
        }
      };
    }

    //for assign project
    case ActionTypes.SET_ASSIGN_PROJECT_DETAILS: {
      const items = [];
      if (action?.payload) {
        items.push(action.payload);
      }
      const existingItems = state?.assignProjectList?.items || [];
      existingItems.forEach((item) => {
        items.push(item);
      });
      const totalCount = (state?.assignProjectList?.totalCount || 0) + 1;
      return {
        ...state,
        assignProjectList: {
          items: items,
          totalCount: totalCount,
        },
      };
    }
    case ActionTypes?.SET_STORE_PACKAGE_DRAWING_LIST: {
      const optionsList = action?.payload?.items?.map((item) => ({
        ...item,
        label: item?.drawing_name || item?.package_name,
        value: item?.id
      }));
      return {
        ...state,
        packageDrawingList: {
          ...action?.payload,
          optionsList: optionsList,
        },
      };
    }
    case ActionTypes.SET_DELETE_PACKAGE_DRAWING: {
      const updatedItems = state?.packageDrawingList?.items?.filter(item => item.id !== action.payload.id);
      const updatedTotalCount = state?.packageDrawingList?.totalCount - 1;

      return {
        ...state,
        packageDrawingList: {
          ...state?.packageDrawingList,
          items: updatedItems,
          totalCount: updatedTotalCount,
        },
      };
    }

    //for listlog
    case ActionTypes.SET_LIST_LOG_LIST: {
      const optionsList = action?.payload?.items?.map((item) => ({
        ...item,
        label: item?.package_name,
        value: item?.id
      }));
      return {
        ...state,
        listLogList: {
          ...state?.listLogList,
          ...action?.payload,
          optionsList: optionsList,
        },
      };
    }
    case ActionTypes.SET_ADD_LIST_LOG: {
      const items = [];
      items.push(action?.payload);
      state?.listLogList?.items?.map((item, index) => {
        items.push(item);
      })
      const totalCount = state?.listLogList?.totalCount + 1;
      return {
        ...state,
        listLogList: {
          items: items,
          totalCount: totalCount,
        }
      };
    }
    case ActionTypes.SET_DELETE_LIST_LOG: {
      const updatedItems = state?.listLogList?.items.filter(item => item.id !== action.payload.id);
      const updatedTotalCount = state?.listLogList?.totalCount - 1;

      return {
        ...state,
        listLogList: {
          ...state?.listLogList,
          items: updatedItems,
          totalCount: updatedTotalCount,
        },
      };
    }
    //for revision history
    case ActionTypes.SET_REVISION_HISTORY_LIST: {
      const optionsList = action?.payload?.items?.map((item) => ({
        ...item,
        label: item?.revision_id,
        value: item?.id
      }));
      return {
        ...state,
        revisionHistoryList: {
          ...state?.revisionHistoryList,
          ...action?.payload,
          optionsList: optionsList,
        },
      };
    }
    case ActionTypes.SET_ADD_REVISION_HISTORY: {
      const items = [];
      items.push(action?.payload);
      state?.revisionHistoryList?.items?.map((item, index) => {
        items.push(item);
      })
      const totalCount = state?.revisionHistoryList?.totalCount + 1;
      return {
        ...state,
        revisionHistoryList: {
          items: items,
          totalCount: totalCount,
        }
      };
    }
    case ActionTypes.SET_DELETE_REVISION_HISTORY: {
      const updatedItems = state?.revisionHistoryList?.items?.filter(item => item.id !== action.payload.id);
      const updatedTotalCount = state?.revisionHistoryList?.totalCount - 1;

      return {
        ...state,
        revisionHistoryList: {
          ...state?.revisionHistoryList,
          items: updatedItems,
          totalCount: updatedTotalCount,
        },
      };
    }

    //for drawing version
    case ActionTypes.SET_DRAWING_VERSION_LIST: {
      const optionsList = action?.payload?.items?.map((item) => ({
        ...item,
        label: item?.revision_id,
        value: item?.id
      }));
      return {
        ...state,
        drawingVersionList: {
          ...state?.drawingVersionList,
          ...action?.payload,
          optionsList: optionsList,
        },
      };
    }
    case ActionTypes.SET_ADD_DRAWING_VERSION: {
      const items = [];
      items.push(action?.payload);
      state?.drawingVersionList.items?.map((item, index) => {
        items.push(item);
      })
      const totalCount = state?.drawingVersionList.totalCount + 1;
      return {
        ...state,
        drawingVersionList: {
          items: items,
          totalCount: totalCount,
        }
      };
    }
    case ActionTypes.SET_DELETE_DRAWING_VERSION: {
      const updatedItems = state?.drawingVersionList.items.filter(item => item.id !== action.payload.id);
      const updatedTotalCount = state?.drawingVersionList.totalCount - 1;

      return {
        ...state,
        drawingVersionList: {
          ...state?.drawingVersionList,
          items: updatedItems,
          totalCount: updatedTotalCount,
        },
      };
    }
    case ActionTypes.SET_STORE_PROJECT_SHOP_SUPPLY:{
      return{
        ...state,
        projectShopSupply: action?.payload,
      }
    }
    default:
      return state;
  }
};

export default projectsReducers;
